.product-add-form > form {
  flex-wrap: wrap;

  @include respond-to-up(lg) {
    align-items: flex-start;
  }

  .bundle-options-wrapper {
    width: 100%;

    @include respond-to-up(lg) {
      width: $bundled-options-w;
    }
  }

  .block-bundle-summary {
    width: 100%;

    @include respond-to-up(lg) {
      width: $bundled-options-summary-w;
    }
  }
}

.page-product-bundle .product-add-form {
  .fieldset-bundle-options > .option {
    padding-bottom: spacers(3);
    border-bottom: $bundled-options-seperator;

    @include respond-to-up(lg) {
      padding-bottom: spacers(3) * 2;
      margin-bottom: spacers(4);
    }

    .price,
    > .label {
      font-weight: $font-weight-bold;
    }

    &.required  > .label {
      &:after {
        content: '*';
        color: theme-color('negative');
      }
    }
  }

  .options-list > .field {
    margin-bottom: spacers(2);

    &:last-child {
      margin-bottom: 0;
    }

    &.choice {
      display: flex;
      max-width: 100%;
    }
  }

  .price-notice ~ .qty {
    margin-top: spacers(3);
  }
}


.page-product-bundle.catalog-product-view:not(.page-product-configurable) .product-add-form>form {
  margin-top: 0;
}