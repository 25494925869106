@charset "UTF-8";
/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
/**
 * Loader to be used across the site
 * Use this variable
 */
/**
 * Form Elements config
 */
/**
 * Collection of generic/global variables used across the theme
 */
.action.primary.customize {
  width: 100%;
  max-width: none;
  margin-bottom: 1rem;
}

.action.primary.customize:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: inherit;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

.action.primary.customize:before {
  margin-right: 0.5rem;
}

.action.back.customization {
  display: none;
}

.page-product-bundle.catalog-product-view .product-info-price {
  align-items: flex-start;
  margin-bottom: 1.5rem;
}

.product-add-form > form {
  flex-wrap: wrap;
}

@media screen and (min-width: 992px) {
  .product-add-form > form {
    align-items: flex-start;
  }
}

.product-add-form > form .bundle-options-wrapper {
  width: 100%;
}

@media screen and (min-width: 992px) {
  .product-add-form > form .bundle-options-wrapper {
    width: 60%;
  }
}

.product-add-form > form .block-bundle-summary {
  width: 100%;
}

@media screen and (min-width: 992px) {
  .product-add-form > form .block-bundle-summary {
    width: 40%;
  }
}

.page-product-bundle .product-add-form .fieldset-bundle-options > .option {
  padding-bottom: 1rem;
  border-bottom: 1px solid #e9ecef;
}

@media screen and (min-width: 992px) {
  .page-product-bundle .product-add-form .fieldset-bundle-options > .option {
    padding-bottom: 2rem;
    margin-bottom: 1.5rem;
  }
}

.page-product-bundle .product-add-form .fieldset-bundle-options > .option .price,
.page-product-bundle .product-add-form .fieldset-bundle-options > .option > .label {
  font-weight: 800;
}

.page-product-bundle .product-add-form .fieldset-bundle-options > .option.required > .label:after {
  content: '*';
  color: #b70e0e;
}

.page-product-bundle .product-add-form .options-list > .field {
  margin-bottom: 0.5rem;
}

.page-product-bundle .product-add-form .options-list > .field:last-child {
  margin-bottom: 0;
}

.page-product-bundle .product-add-form .options-list > .field.choice {
  display: flex;
  max-width: 100%;
}

.page-product-bundle .product-add-form .price-notice ~ .qty {
  margin-top: 1rem;
}

.page-product-bundle.catalog-product-view:not(.page-product-configurable) .product-add-form > form {
  margin-top: 0;
}

.block-bundle-summary {
  position: relative;
  padding: 1.5rem;
  background: #eee;
}

.block-bundle-summary:before {
  width: 0;
  height: 0;
  content: '';
  z-index: 2;
  border-left: 0.75em solid currentcolor;
  border-bottom: 0.5em solid transparent;
  border-top: 0.5em solid transparent;
  position: absolute;
  top: 2.1rem;
  left: 0;
  content: '';
}

.block-bundle-summary .title {
  font-size: 1.5rem;
}

.block-bundle-summary .title strong {
  font-weight: normal;
}

.block-bundle-summary .product.name {
  display: block;
  margin-bottom: 0.5rem;
}

.block-bundle-summary .subtitle {
  color: #767676;
  margin-bottom: 0.5rem;
}

.block-bundle-summary .product-image-container {
  display: none;
}

.block-bundle-summary .bundle-summary {
  margin-bottom: 86px;
}

.block-bundle-summary .stock.available {
  display: inline-block;
}

@media screen and (max-width: 992px) {
  .block-bundle-summary._sticky {
    top: 0 !important;
  }
}

.page-product-bundle.catalog-product-view .box-tocart {
  margin-bottom: 1rem;
}

@media screen and (max-width: 992px) {
  .page-product-bundle .product-reviews-summary .action.add {
    max-width: none;
  }
}

@media screen and (max-width: 992px) {
  .page-product-bundle .product-options-wrapper {
    margin-bottom: 0;
  }
}
